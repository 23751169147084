import React from 'react'
import FixedLayout from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout'
import Progress from '@vkontakte/vkui/dist/components/Progress/Progress'
import Icon48SkipPrevious from '@vkontakte/icons/dist/48/skip_previous';
import Icon48SkipNext from '@vkontakte/icons/dist/48/skip_next';
import Icon48Pause from '@vkontakte/icons/dist/48/pause';
import Icon48Play from '@vkontakte/icons/dist/48/play';
import './BottomPlayer.css'

export default class BottomPlayer extends React.Component {
  render () {
    return (
      <FixedLayout vertical="bottom">
        <div className="BottomPlayer">
          <div className="BottomPlayer__topRow">
            <div>{this.props.status.title}</div>
            <div className="BottomPlayer__artist">{this.props.status.artist}</div>
          </div>
          <div className="BottomPlayer__midRow">
            <Icon48SkipPrevious onClick={this.props.onClickPrev} />
            {this.props.status.isPlaying ? <Icon48Pause onClick={this.props.onClickPause} /> : <Icon48Play onClick={this.props.onClickPlay} />}
            <Icon48SkipNext onClick={this.props.onClickNext} />
          </div>
          <div className="BottomPlayer__bottomRow">
            <div>{this.props.status.current}</div>
            <Progress value={this.props.status.progress * 100} />
            <div>{this.props.status.total}</div>
          </div>
        </div>
      </FixedLayout>
    )
  }
}
