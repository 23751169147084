import React from 'react'
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel'
import PanelHeader from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader'
import Group from '@vkontakte/vkui/dist/components/Group/Group'
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar'
import List from '@vkontakte/vkui/dist/components/List/List'
import Cell from '@vkontakte/vkui/dist/components/Cell/Cell'
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner'
import Icon24Play from '@vkontakte/icons/dist/24/play';
import BottomPlayer from '../components/BottomPlayer'
import io from 'socket.io-client'

class Home extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      log: '',
      connected: false,
      status: {
        title: '',
        artist: '',
        progress: 0,
        total: '',
        current: '',
        isPlaying: false
      },
      playlist: []
    }

    this.socket = null
  }

  componentDidMount () {
    this.socket = io('https://remote.vitalya.me', { query: { params: window.location.search.slice(1), role: 'client' } })

    this.socket.on('connect', () => {
      this.setState({ connected: true })
      this.props.setPopout(null)
      this.socket.emit('getStatus')
    })

    this.socket.on('disconnect', () => {
      this.setState({ connected: false })
      this.props.setPopout(<ScreenSpinner />)
    })

    this.socket.on('progress', data => {
      this.setState({
        status: {
          title: data.audio.title,
          artist: data.audio.artist,
          id: data.audio.id,
          progress: data.progress,
          total: data.total,
          current: data.current,
          isPlaying: data.isPlaying
        }
      })
    })

    this.socket.on('playlist', playlist => {
      this.setState({ playlist })
    })
  }

  emit = (name, ...arg) => () => this.socket.emit(name, ...arg)

  onClickNext = this.emit('next')
  onClickPrev = this.emit('prev')
  onClickPause = this.emit('pause')
  onClickPlay = this.emit('play')

  render () {
    return (
      <Panel id={this.props.id}>
        <PanelHeader>VK Remote</PanelHeader>
        <Group title="Playlist" style={{ marginBottom: 150 }}>
          <List>
            {this.state.playlist.map(e => {
              // console.log(e)
              const id = `${e[1]}_${e[0]}`
              const decodeHTML = function (html) {
                var txt = document.createElement('textarea');
                txt.innerHTML = html;
                return txt.value;
              }
              const secToTime = sec => `${Math.floor(sec / 60)}:${Math.floor(sec % 60).toString().padStart(2, '0')}`

              return (
                <Cell
                  before={<Avatar type="image" src={e[14].split(',')[0]} />}
                  key={id}
                  description={decodeHTML(e[4])}
                  onClick={() => this.socket.emit('toggleAudio', id)}
                  asideContent={id === this.state.status.id ? <Icon24Play style={{ color: 'var(--accent)' }} /> : secToTime(e[5])}
                >{decodeHTML(e[3])}</Cell>
              )
            })}
          </List>
        </Group>
        <BottomPlayer
          status={this.state.status}
          onClickNext={this.onClickNext}
          onClickPrev={this.onClickPrev}
          onClickPause={this.onClickPause}
          onClickPlay={this.onClickPlay}
        />
      </Panel>
    )
  }
}

export default Home
