import React, { useState } from 'react'
import View from '@vkontakte/vkui/dist/components/View/View'
import ScreenSpinner from '@vkontakte/vkui/dist/components/ScreenSpinner/ScreenSpinner'
import '@vkontakte/vkui/dist/vkui.css'

import Home from './panels/Home'

const App = () => {
  const [activePanel, setActivePanel] = useState('home')
  const [popout, setPopout] = useState(<ScreenSpinner />)
  
  const go = e => {
    setActivePanel(e.currentTarget.dataset.to)
  }

  return (
    <View activePanel={activePanel} popout={popout}>
      <Home id='home' go={go} setPopout={setPopout} />
    </View>
  )
}

export default App

